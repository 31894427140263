// define store from vuex and add (user - auth) modules to store
import { createStore } from 'vuex';
// import user moduls
import user from './modules/user';
// import auth moduls
import auth from './modules/auth';
// import settings moduls
import settings from './modules/settings';


const store = createStore({
    modules: {
        user,
        auth,
        settings
    }
});

export default store;