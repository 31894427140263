import { ref } from "vue";
// define user module to handeling the user data after login
const state = {
    DEFAULT_TITLE: "Touta World",
    breadcrumb: ref(),
    blogData: {
        id: ref(),
        title: ref(),
        slug: ref(),
        sub_title: ref(),
        short_content: ref(),
        category_id: ref(),
        sub_category_id: ref(),
        views: ref(),
        downloads: ref(),
        version: ref(),
        publish_date: ref(),
        created_user_id: ref(),
    },
};

const getters = {
    getPageTitle(state) {
        return state.DEFAULT_TITLE;
    },
    getBreadcrumb(state) {
        return state.breadcrumb;
    },
    // Edit Data
    getBlogDataToEdit(state) {
        return state.blogData;
    },
};

const actions = {};

const mutations = {
    setPageTitle(state, metaData) {
        state.DEFAULT_TITLE = metaData.title || state.DEFAULT_TITLE;
        document.title = metaData.title || state.DEFAULT_TITLE;

        const meta_name_description = document.querySelector(
            'meta[name="description"]'
        );
        const meta_name_keywords = document.querySelector(
            'meta[name="keywords"]'
        );
        const meta_name_thumbnail = document.querySelector(
            'meta[name="thumbnail"]'
        );
        const meta_name_author = document.querySelector('meta[name="author"]');
        const meta_name_msapplication_TileColor = document.querySelector(
            'meta[name="msapplication-TileColor"]'
        );
        const meta_name_msapplication_square70x70logo = document.querySelector(
            'meta[name="msapplication-square70x70logo"]'
        );
        const meta_name_msapplicationsquare150x150logo = document.querySelector(
            'meta[name="msapplicationsquare150x150logo"]'
        );
        const meta_name_msapplicationwide310x150logo = document.querySelector(
            'meta[name="msapplicationwide310x150logo"]'
        );
        const meta_name_msapplicationsquare310x310logo = document.querySelector(
            'meta[name="msapplicationsquare310x310logo"]'
        );
        const meta_property_og_title = document.querySelector(
            "meta[property='og:title']"
        );
        const meta_property_og_url = document.querySelector(
            'meta[property="og:url"]'
        );
        const meta_property_og_image_secure_url = document.querySelector(
            'meta[property="og:image:secure_url"]'
        );
        const meta_property_og_image = document.querySelector(
            'meta[property="og:image"]'
        );
        const meta_property_og_image_width = document.querySelector(
            'meta[property="og:image:width"]'
        );
        const meta_property_og_image_height = document.querySelector(
            'meta[property="og:image:height"]'
        );
        const meta_property_og_site_name = document.querySelector(
            'meta[property="og:site_name"]'
        );
        const meta_property_og_description = document.querySelector(
            'meta[property="og:description"]'
        );
        const meta_property_og_type = document.querySelector(
            'meta[property="og:type"]'
        );
        const meta_property_og_image_alt = document.querySelector(
            'meta[property="og:image:alt"]'
        );
        const meta_property_twitter_card = document.querySelector(
            'meta[property="twitter:card"]'
        );
        const meta_property_twitter_site = document.querySelector(
            'meta[property="twitter:site"]'
        );
        const meta_property_twitter_creator = document.querySelector(
            'meta[property="twitter:creator"]'
        );
        const meta_property_twitter_title = document.querySelector(
            'meta[property="twitter:title"]'
        );
        const meta_property_twitter_description = document.querySelector(
            'meta[property="twitter:description"]'
        );
        const meta_property_twitter_image = document.querySelector(
            'meta[property="twitter:image"]'
        );
        const meta_property_twitter_image_alt = document.querySelector(
            'meta[property="twitter:image:alt"]'
        );
        const titleEl = document.querySelector("title");

        meta_name_description.setAttribute(
            "content",
            metaData.meta_name_description
        );
        meta_name_keywords.setAttribute("content", metaData.meta_name_keywords);
        meta_name_thumbnail.setAttribute(
            "content",
            metaData.meta_name_thumbnail
        );
        meta_name_author.setAttribute("content", metaData.meta_name_author);
        meta_name_msapplication_TileColor.setAttribute(
            "content",
            metaData.meta_name_msapplication_TileColor
        );
        meta_name_msapplication_square70x70logo.setAttribute(
            "content",
            metaData.meta_name_msapplication_square70x70logo
        );
        meta_name_msapplicationsquare150x150logo.setAttribute(
            "content",
            metaData.meta_name_msapplicationsquare150x150logo
        );
        meta_name_msapplicationwide310x150logo.setAttribute(
            "content",
            metaData.meta_name_msapplicationwide310x150logo
        );
        meta_name_msapplicationsquare310x310logo.setAttribute(
            "content",
            metaData.meta_name_msapplicationsquare310x310logo
        );
        meta_property_og_title.setAttribute(
            "content",
            metaData.meta_property_og_title
        );
        meta_property_og_url.setAttribute(
            "content",
            metaData.meta_property_og_url
        );
        meta_property_og_image_secure_url.setAttribute(
            "content",
            metaData.meta_property_og_image_secure_url
        );
        meta_property_og_image.setAttribute(
            "content",
            metaData.meta_property_og_image
        );
        meta_property_og_image_width.setAttribute(
            "content",
            metaData.meta_property_og_image_width
        );
        meta_property_og_image_height.setAttribute(
            "content",
            metaData.meta_property_og_image_height
        );
        meta_property_og_site_name.setAttribute(
            "content",
            metaData.meta_property_og_site_name
        );
        meta_property_og_description.setAttribute(
            "content",
            metaData.meta_property_og_description
        );
        meta_property_og_type.setAttribute(
            "content",
            metaData.meta_property_og_type
        );
        meta_property_og_image_alt.setAttribute(
            "content",
            metaData.meta_property_og_image_alt
        );
        meta_property_twitter_card.setAttribute(
            "content",
            metaData.meta_property_twitter_card
        );
        meta_property_twitter_site.setAttribute(
            "content",
            metaData.meta_property_twitter_site
        );
        meta_property_twitter_creator.setAttribute(
            "content",
            metaData.meta_property_twitter_creator
        );
        meta_property_twitter_title.setAttribute(
            "content",
            metaData.meta_property_twitter_title
        );
        meta_property_twitter_description.setAttribute(
            "content",
            metaData.meta_property_twitter_description
        );
        meta_property_twitter_image.setAttribute(
            "content",
            metaData.meta_property_twitter_image
        );
        meta_property_twitter_image_alt.setAttribute(
            "content",
            metaData.meta_property_twitter_image_alt
        );
    },

    setBreadcrumb(state, payload) {
        state.breadcrumb = ref(payload.items);
    },

    setBlogDataToEdit(state, payload) {
        state.blogData.id = payload.blogData.id;
        state.blogData.title = payload.blogData.title;
        state.blogData.slug = payload.blogData.slug;
        state.blogData.sub_title = payload.blogData.sub_title;
        state.blogData.short_content = payload.blogData.short_content;
        state.blogData.category_id = payload.blogData.sub_category.category_id;
        state.blogData.sub_category_id = payload.blogData.sub_category_id;
        state.blogData.views = payload.blogData.views;
        state.blogData.downloads = payload.blogData.downloads;
        state.blogData.version = payload.blogData.version;
        state.blogData.publish_date = payload.blogData.publish_date;
        state.blogData.created_user_id = payload.blogData.created_user_id;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
