<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' }]
    },
    {
        label: 'Admin',
        items: [
            { label: 'Users', icon: 'pi pi-fw pi-users', to: '/admin/users' },
            { label: 'CFM', icon: 'pi pi-fw pi-users', to: '/admin/CFM' },
            { label: 'Lists', icon: 'pi pi-fw pi-users', to: '/admin/Lists' }
        ],
    },
    {
        label: 'Controllers',
        items: [
            { label: 'Blogs', icon: 'pi pi-fw pi-book', to: '/admin/controllers/blogs' },
            { label: 'Dashboard', icon: 'pi pi-fw pi-chart-bar', to: '/admin/controllers/dashboard' },
            { label: 'Facebook Graph', icon: 'pi pi-fw pi-facebook', to: '/admin/controllers/facebook-graph-handler' },
        ],
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
