import "./bootstrap";
import router from "@/router/routes.js";
import store from "@/vuex/store.js";
import axios from "axios";
import VueAxios from "vue-axios";
// import google Oauth2 Library
import vue3GoogleLogin from "vue3-google-login";
// import facebook Oauth2 Library
import * as Facebook from "fb-sdk-wrapper";

// Start Dashboard
import PrimeVue from "primevue/config";
import Aura from '@primevue/themes/aura';
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import ButtonGroup from "primevue/buttongroup";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Chart from "primevue/chart";
import CascadeSelect from "primevue/cascadeselect";
import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import DeferredContent from "primevue/deferredcontent";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import Divider from "primevue/divider";
import Dock from "primevue/dock";
import Dropdown from "primevue/dropdown";
import DynamicDialog from "primevue/dynamicdialog";
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import FloatLabel from "primevue/floatlabel";
import Galleria from "primevue/galleria";
import IconField from "primevue/iconfield";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputIcon from "primevue/inputicon";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import Knob from "primevue/knob";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import Row from "primevue/row";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Skeleton from "primevue/skeleton";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tag from "primevue/tag";
import Terminal from "primevue/terminal";
import Timeline from "primevue/timeline";
import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
import Tree from "primevue/tree";
import TreeSelect from "primevue/treeselect";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";
import VirtualScroller from "primevue/virtualscroller";
import Editor from "primevue/editor";

// import Drawer from 'primevue/drawer';

import BlockViewer from "@/components/BlockViewer.vue";

import AppLayout from "@/layout/AppLayout.vue";

import VueInstantSearch from "vue-instantsearch/vue3/es";

import "../scss/app.scss";
// End Dashboard

window.Vue = createApp;

// Start Dashboard
createApp.use(PrimeVue, {
    ripple: true,
    theme: {
        preset: Aura
    }
 });
createApp.use(ToastService);
createApp.use(DialogService);
createApp.use(ConfirmationService);

createApp.directive("tooltip", Tooltip);
createApp.directive("badge", BadgeDirective);
createApp.directive("ripple", Ripple);
createApp.directive("styleclass", StyleClass);

createApp.component("AppLayout", AppLayout);

createApp.component("BlockViewer", BlockViewer);

createApp.component("Accordion", Accordion);
createApp.component("AccordionTab", AccordionTab);
createApp.component("AutoComplete", AutoComplete);
createApp.component("Avatar", Avatar);
createApp.component("AvatarGroup", AvatarGroup);
createApp.component("Badge", Badge);
createApp.component("BlockUI", BlockUI);
createApp.component("Breadcrumb", Breadcrumb);
createApp.component("Button", Button);
createApp.component("ButtonGroup", ButtonGroup);
createApp.component("Calendar", Calendar);
createApp.component("Card", Card);
createApp.component("Chart", Chart);
createApp.component("Carousel", Carousel);
createApp.component("CascadeSelect", CascadeSelect);
createApp.component("Checkbox", Checkbox);
createApp.component("Chip", Chip);
createApp.component("Chips", Chips);
createApp.component("ColorPicker", ColorPicker);
createApp.component("Column", Column);
createApp.component("ColumnGroup", ColumnGroup);
createApp.component("ConfirmDialog", ConfirmDialog);
createApp.component("ConfirmPopup", ConfirmPopup);
createApp.component("ContextMenu", ContextMenu);
createApp.component("DataTable", DataTable);
createApp.component("DataView", DataView);
createApp.component("DataViewLayoutOptions", DataViewLayoutOptions);
createApp.component("DeferredContent", DeferredContent);
createApp.component("Dialog", Dialog);
createApp.component("Divider", Divider);
createApp.component("Dock", Dock);
createApp.component("Dropdown", Dropdown);
createApp.component("DynamicDialog", DynamicDialog);
createApp.component("Fieldset", Fieldset);
createApp.component("FileUpload", FileUpload);
createApp.component("FloatLabel", FloatLabel);
createApp.component("Galleria", Galleria);
createApp.component("IconField", IconField);
createApp.component("Image", Image);
createApp.component("InlineMessage", InlineMessage);
createApp.component("Inplace", Inplace);
createApp.component("InputGroup", InputGroup);
createApp.component("InputGroupAddon", InputGroupAddon);
createApp.component("InputIcon", InputIcon);
createApp.component("InputMask", InputMask);
createApp.component("InputNumber", InputNumber);
createApp.component("InputSwitch", InputSwitch);
createApp.component("InputText", InputText);
createApp.component("Knob", Knob);
createApp.component("Listbox", Listbox);
createApp.component("MegaMenu", MegaMenu);
createApp.component("Menu", Menu);
createApp.component("Menubar", Menubar);
createApp.component("Message", Message);
createApp.component("MultiSelect", MultiSelect);
createApp.component("OrderList", OrderList);
createApp.component("OrganizationChart", OrganizationChart);
createApp.component("OverlayPanel", OverlayPanel);
createApp.component("Paginator", Paginator);
createApp.component("Panel", Panel);
createApp.component("PanelMenu", PanelMenu);
createApp.component("Password", Password);
createApp.component("PickList", PickList);
createApp.component("ProgressBar", ProgressBar);
createApp.component("ProgressSpinner", ProgressSpinner);
createApp.component("RadioButton", RadioButton);
createApp.component("Rating", Rating);
createApp.component("Row", Row);
createApp.component("SelectButton", SelectButton);
createApp.component("ScrollPanel", ScrollPanel);
createApp.component("ScrollTop", ScrollTop);
createApp.component("Slider", Slider);
createApp.component("Sidebar", Sidebar);
createApp.component("Skeleton", Skeleton);
createApp.component("SpeedDial", SpeedDial);
createApp.component("SplitButton", SplitButton);
createApp.component("Splitter", Splitter);
createApp.component("SplitterPanel", SplitterPanel);
createApp.component("Steps", Steps);
createApp.component("TabMenu", TabMenu);
createApp.component("TabView", TabView);
createApp.component("TabPanel", TabPanel);
createApp.component("Tag", Tag);
createApp.component("Textarea", Textarea);
createApp.component("Terminal", Terminal);
createApp.component("TieredMenu", TieredMenu);
createApp.component("Timeline", Timeline);
createApp.component("Toast", Toast);
createApp.component("Toolbar", Toolbar);
createApp.component("ToggleButton", ToggleButton);
createApp.component("Tree", Tree);
createApp.component("TreeSelect", TreeSelect);
createApp.component("TreeTable", TreeTable);
createApp.component("TriStateCheckbox", TriStateCheckbox);
createApp.component("VirtualScroller", VirtualScroller);
createApp.component("Editor", Editor);
// createApp.component("Drawer", Drawer);

// End Dashboard
createApp.use(VueAxios, axios);
// import Vue from 'vue'
// import { devtools } from '@vue/devtools';
// if (process.env.NODE_ENV === 'development') {
//     // devtools.connect();
//     devtools.connect('http://localhost', 8080);
// }
createApp.use(vue3GoogleLogin, {
    // google Oauth2 client ID
    clientId:
        "110803042006-ggetf95dqats6qehr5g9fipge30k4hil.apps.googleusercontent.com",
});

createApp.use(VueInstantSearch);

// set facebook as global variable
window.Facebook = Facebook;
createApp.use(router);
createApp.use(store);
createApp.mount("#app");

{
    /* <script type="text/javascript">
    Add Extra Info to Copied Text
    function addLink() {
        if (window.getSelection().containsNode(document.getElementsByClassName('article-text')[0], true)) {
            var selectiontext = window.getSelection();
            var htmloverflowx = $('html').css('overflow-x');
            var sel = window.getSelection();
            var pagelink = "<br /><br /> إقرأ المزيد على موضوع.كوم: <a href='" + document.location.href + "'>" + document.location.href + "</a>";
            var div = $('<div>', {
                class: 'copy-paste-extra-nu',
                style: 'position:fixed;width:0px;height:0px;',
                html: sel + pagelinkl
            });
            $('html').css('overflow-x', 'hidden');
            $('body').append(div);
            sel.selectAllChildren(div[0]);


            window.setTimeout(function() {
                $('.copy-paste-extra-nu').remove();
                $('html').css('overflow-x', htmloverflowx);
            }, 0);

        }

    }


    document.oncopy = addLink;

    Add Extra Info to Copied Text
</script> */
}