import Cookies from "js-cookie";
import Errors from "./../../modules/errors";
import { googleLogout } from "vue3-google-login";

const state = {
    access_token: Cookies.get("access_token") || null,
    oauth_provider: Cookies.get("oauth_provider") || "",
    status: "",
    hasLoadedOnnull: false,
    errors: new Errors(),
};

const getters = {
    isAuthenticated(state) {
        return !!state.access_token;
    },
    getAccessToken(state) {
        return state.access_token;
    },
    getOauthProvider(state) {
        return state.oauth_provider;
    },
    authStatus(state) {
        return state.status;
    },
    authErrors(state) {
        return state.errors;
    },
};

const actions = {
    authRequest({ commit, dispatch }, payload, state) {
        let actionUrl = "/api/login";
        let remember = payload.remember ? payload.remember : false;

        let formData = new FormData();

        formData.append("email", payload.email);
        formData.append("password", payload.password);
        formData.append("remember", remember);

        if (payload.action == "register") {
            actionUrl = "/api/register";
            formData = new FormData();

            formData.append("name", payload.name);
            formData.append("email", payload.email);
            formData.append("password", payload.password);
            formData.append(
                "password_confirmation",
                payload.password_confirmation
            );
            formData.append("profile_image", payload.profile_image);
            formData.append("remember", remember);
        }

        if (payload.action == "add user") {
            actionUrl = "/api/admin/users";
            formData = new FormData();

            formData.append("name", payload.name);
            formData.append("email", payload.email);
            formData.append("password", payload.password);
            formData.append(
                "password_confirmation",
                payload.password_confirmation
            );
            formData.append("profile_image", payload.profile_image);
            formData.append("type", payload.type);
            formData.append("remember", remember);
        }

        if (payload.action == "edit user") {
            actionUrl = "/api/admin/users/" + payload.id;
            formData = new FormData();

            formData.append("_method", "put");
            formData.append("name", payload.name);
            formData.append("email", payload.email);
            formData.append("password", payload.password);
            formData.append(
                "password_confirmation",
                payload.password_confirmation
            );
            formData.append("profile_image", payload.profile_image);
            formData.append("type", payload.type);
            formData.append("remember", remember);
        }

        if (payload.action == "delete user") {
            actionUrl = "/api/admin/users/" + payload.id;
            formData = new FormData();

            formData.append("_method", "delete");
        }

        if (payload.action == "user activation") {
            actionUrl = "/api/admin/users/activation/" + payload.id;
            formData = new FormData();
        }

        if (
            payload.action == "add user" ||
            payload.action == "edit user" ||
            payload.action == "delete user" ||
            payload.action == "user activation"
        ) {
            return new Promise((resolve, reject) => {
                commit("authRequest");

                axios
                    .post(actionUrl, formData)
                    .then((res) => {
                        dispatch("userRequest");
                        resolve(res);
                    })
                    .catch((err) => {
                        commit("authError", err.response.data);
                        reject(err);
                    });
            });
        }

        if (payload.action == "password-reset") {
            actionUrl = "/api/password/reset";

            formData = new FormData();
            formData.append("token", payload.token);
            formData.append("email", payload.email);
            formData.append("password", payload.password);
            formData.append(
                "password_confirmation",
                payload.password_confirmation
            );
        }
        if (payload.action != "add user") {
            return new Promise((resolve, reject) => {
                commit("authRequest");

                axios
                    .post(actionUrl, formData)
                    .then((res) => {
                        let access_token = "Bearer " + res.data.access_token;
                        Cookies.set("access_token", access_token, {
                            expires: remember ? 365 : 1,
                        });
                        axios.defaults.headers.common["Authorization"] =
                            access_token;

                        commit("authSuccess", {
                            access_token: access_token,
                            oauth_provider: null,
                        });
                        dispatch("userRequest");
                        resolve(access_token);
                    })
                    .catch((err) => {
                        commit("authError", err.response.data);
                        Cookies.remove("access_token");
                        Cookies.remove("oauth_provider");
                        reject(err);
                    });
            });
        }
    },
    authSocialiteRequest({ commit, dispatch }, payload) {
        let actionUrl = "/api/auth/" + payload.provider + "/login";
        let remember = payload.remember ? payload.remember : false;

        let formData = new FormData();

        formData.append("email", payload.userData.email);
        formData.append("oauth_uid", payload.userData.sub);
        formData.append("remember", remember);

        if (payload.action == "register") {
            actionUrl = "/api/auth/" + payload.provider + "/signup";
            formData = new FormData();

            formData.append("name", payload.userData.name);
            formData.append("email", payload.userData.email);
            formData.append("password", payload.socialPassword);
            formData.append(
                "password_confirmation",
                payload.socialPassword_confirmation
            );
            formData.append("profile_image", payload.userData.picture);
            formData.append("oauth_uid", payload.userData.sub);
            formData.append("remember", remember);
        }

        return new Promise((resolve, reject) => {
            commit("authRequest");

            axios
                .post(actionUrl, formData)
                .then((resp) => {
                    let access_token = "Bearer " + resp.data.access_token;
                    Cookies.set("access_token", access_token, {
                        expires: remember ? 365 : 1,
                    });
                    axios.defaults.headers.common["Authorization"] =
                        access_token;
                    Cookies.set("oauth_provider", payload.provider, {
                        expires: remember ? 365 : 1,
                    });

                    commit("authSuccess", {
                        access_token: access_token,
                        oauth_provider: payload.provider,
                    });
                    resolve(resp);
                    resolve(access_token);
                })
                .catch((err) => {
                    commit("authError", err.response.data);
                    console.log(err.response.data);
                    Cookies.remove("access_token");
                    Cookies.remove("oauth_provider");
                    reject(err);
                });
        });
    },
    async authLogout({ commit, dispatch }, payload) {
        Cookies.remove("access_token");

        switch (state.oauth_provider) {
            case "google":
                googleLogout();
                break;

            case "facebook":
                const fb = Facebook;
                await fb.load();
                fb.logout().then((response) => {
                    console.log(response);
                });
                break;

            case "github":
                break;

            default:
                break;
        }

        return new Promise((resolve, reject) => {
            axios
                .post("/api/logout")
                .then((resp) => {
                    commit("authLogout");
                    resolve();
                })
                .catch((err) => {
                    commit("authError", err.response.data);
                    reject(err);
                });
        });
    },
};

const mutations = {
    authRequest(state) {
        state.status = "loading";
    },
    authSuccess(state, payload) {
        state.status = "success";
        state.access_token = payload.access_token;
        state.oauth_provider = payload.oauth_provider || "";
        state.hasLoadedOnce = true;
    },
    authError(state, err) {
        let errors = err.errors ? err.errors : { invalid_credentials: err };
        // let errors = { invalid_credentials: err };
        state.status = "error";
        state.hasLoadedOnce = true;
        state.errors.record(errors);
    },
    authLogout(state) {
        state.access_token = "";
    },
    updateToken(state) {
        let access_token = Cookies.get("access_token") || null;

        if (access_token != null) {
            state.access_token = access_token;
            axios.defaults.headers.common["Authorization"] = access_token;
            axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (error.response.status === 401) {
                        Cookies.remove("access_token");
                        // localStorage.removeItem("userToken");
                        axios.defaults.headers.common.Authorization = "";

                        state.usertoken = null;
                        var urlArr = window.location.href.split(":");
                        if (urlArr[urlArr.length - 1] != "8000") {
                            window.location.href = "/login";
                        }
                        // window.location.reload();
                    }

                    return Promise.reject(error);
                }
            );
        } else {
            // console.log("Token is Empty");
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
