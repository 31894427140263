<script setup>
import { useLayout } from '@/layout/composables/layout';
import { ref, computed } from 'vue';

const { layoutConfig } = useLayout();

const currentYear = ref(new Date());

const logoUrl = computed(() => {
    return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
});
</script>

<template>
    <div class="layout-footer rtl">
        <!-- <span class="font-medium ml-2">© {{ currentYear.getFullYear() }} <span style="color: #fb8500;"><span
                    style="color: #003049;">T</span>ou<span style="color: #003049;">t</span>a <span
                    style="color: #003049;">W</span>or<span style="color: #003049;">l</span>d</span></span> -->
        <!-- <img :src="logoUrl" alt="Logo" height="20" class="mr-2" /> -->
        <div class="container d-flex flex-wrap justify-content-center footer-container">
            <div class="contact-us d-flex flex-wrap">
                <a href="'/privacy-policy'" targer="_blank" class="text-white" :aria-label="`privacy policy`">سياسة
                    الخصوصية</a>
                <Divider layout="vertical" class="float-start" style="height: 60%;" />
                <a href="'/contact-us'" targer="_blank" class="text-white" :aria-label="`contact us`">تواصل معنا</a>
            </div>
            <div class="footer-socials d-flex flex-wrap">
                <a href="https://www.facebook.com/toutaworld" target="_blank" class="mt-a icon-link"
                    aria-label="Follow us on facebook">
                    <i class="bi bi-facebook"></i>
                </a>
                <a href="#" target="_blank" class="icon-link mt-10" aria-label="Follow us on instagram"
                   >
                    <i class="bi bi-instagram"></i>
                </a>
                <a href="#" target="_blank" class="icon-link mt-10" aria-label="Follow us on twitter">
                    <i class="bi bi-twitter"></i>
                </a>
                <a href="#" target="_blank" class="icon-link mt-10" aria-label="Follow us on youtube">
                    <i class="bi bi-youtube"></i>
                </a>
            </div>
            <div class="copy-right ltr">
                <span class="font-medium ml-2">© {{ currentYear.getFullYear() }}
                    <span>Touta World</span>
                </span>
            </div>
        </div>
    </div>
</template>
<style lang="css">
.layout-footer {
    background-color: #1e293b;
}

.layout-footer a,
.layout-footer span {
    color: #ffffff;
}

.layout-footer .footer-container>div {
    padding: 20px;
}

.layout-footer .footer-container .footer-socials .icon-link>i {
    width: 2rem;
    height: 2rem;
    margin: 0 10px;
}

.layout-footer .footer-container .contact-us>a {
    padding: 10px;
}

.layout-footer .footer-container .copy-right {
    padding-top: 30px;
}
</style>
