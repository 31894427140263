// define user module to handeling the user data after login
const state = {
    status: "",
    profile: {},
};

const getters = {
    getProfile(state) {
        return state.profile;
    },
    isAdmin(state) {
        if (state.profile) {
            return state.profile.type === "Admin" ? true : false;
        }
        return false;
    },
    isProfileLoaded(state) {
        return !!state.profile.name;
    },
};

const actions = {
    // get the user data from database
    userRequest({ commit, dispatch }) {
        commit("userRequest");
        axios
            .get("/api/user")
            .then((resp) => {
                commit("userSuccess", resp.data.user);
            })
            .catch((err) => {
                console.log(err);
                commit("userError");
                dispatch("authLogout");
            });
    },
};

const mutations = {
    userRequest(state) {
        state.status = "loading";
    },
    userSuccess(state, resp) {
        state.status = "success";
        state.profile = resp;
    },
    userError(state) {
        state.status = "error";
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
