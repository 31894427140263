<script setup>
import { computed, onBeforeMount, onUpdated, watch, ref } from 'vue';
import AppTopbar from './AppTopbar.vue';
import NavBar from './NavBar.vue';
import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import { useLayout } from '@/layout/composables/layout';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Cookies from 'js-cookie';

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);

const store = useStore();
const route = useRoute();

const visibleLeft = ref(false);

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

// Start onMounted Section
onBeforeMount(() => {
    if (!Cookies.get('first_time_cookies_acception')) {
        
        visibleLeft.value = true;
        Cookies.set('first_time_cookies_acception', 'v1');
    }
    updateToken();
    if (isLogged.value) {
        updateUser();
    }

});
// End onMounted Section

// Start onUpdated Section
onUpdated(() => {

});
// End onUpdated Section

// Start Computed Section
const isAdmin = computed(() => {
    return store.getters.isAdmin;
});

const user = computed(() => {
    return store.getters.getProfile;
});

const isLogged = computed(() => {
    return store.getters.isAuthenticated;
});

const userToken = computed(() => {
    return store.getters.getAccessToken;
});

const containerClass = computed(() => {
    return {
        'layout-theme-light': layoutConfig.darkTheme.value === 'light',
        'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-ripple-disabled': layoutConfig.ripple.value === false
    };
});
// End Computed Section

// Start Methods Section
const updateToken = () => {

    store.commit('updateToken');
    if (isLogged) {

    }
};

const updateUser = () => {

    store.dispatch('userRequest');
};

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false;
                layoutState.staticMenuMobileActive.value = false;
                layoutState.menuHoverActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.layout-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
// End Methods Section
</script>

<template>
    <div class="layout-wrapper global-home-container rtl" :class="containerClass">
        <app-topbar />
        <!-- <nav-bar /> -->
        <!-- <div class="layout-sidebar"> -->
        <div v-if="isAdmin" class="layout-sidebar ltr">
            <app-sidebar></app-sidebar>
        </div>
        <div :class="['layout-main-container', { 'not-a': isAdmin != true, 'mb-50': route.path != '/','home-layout-main-container':  route.name  == 'home' }]">
            <div :class="['layout-main',
                    {'home-layout-main': route.name  == 'home'}]">
                <router-view />
            </div>
        </div>
        <app-footer v-if="route.path != '/'"></app-footer>
        <app-config></app-config>
        <div class="layout-mask"></div>
    </div>
    <div id="cookie-popup" v-if="visibleLeft">
        <p class="tajawal-regular fs-1">
            هذا الموقع يستخدم ملفات الارتباط (الكوكيز) للتأكد من حصولك على افضل تجربة على موقعنا.
            This website uses cookies to ensure you get 
            the best experience on our website. 
        </p>
        <button @click.prevent="visibleLeft = false">
            Got it!
        </button>
    </div>
    <!-- <div class="card">
        <div class="flex gap-2 justify-center">
            <Button icon="pi pi-arrow-right" @click="visibleLeft = true" />
            </div>
        <Drawer v-model:visible="visibleLeft" header="Left Drawer">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </Drawer>
    </div> -->
    <div class="fix-clear"></div>
    <Toast />
</template>

<style lang="css">
a {
    color: var(--surface-900) !important;
}

.not-a {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
}

.mb-50 {
    margin-top: 50px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mr-50 {
    margin-top: 50px !important;
}

.ml-50 {
    margin-top: 50px !important;
}

@font-face {
    font-family: TajawalRegular;
    src: url('../../fonts/Tajawal-Regular.ttf');
}

* {
    font-family: TajawalRegular;
}

.tajawal-regular {
    font-family: TajawalRegular;
}

dl, ol, ul {
    list-style-type: none;
}

.fix-clear {
    clear: both;
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

.custom-loading {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff77 !important;
}

.home-layout-main-container {
    position: relative;
}

.home-layout-main {    
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.router-link-exact-active {
    color: #aaaaaa !important;
}

.nsm7Bb-HzV7m-LgbsSe,
.jVeSEe,
.i5vt6e-Ia7Qfc,
.uaxL4e-RbRzK {
    background-color: #000000 !important;
    color: #000000 !important;
}

.error-font {
    color: #ff0000;
}

.p-megamenu-root-list {
    margin: 0 !important;
}

.inline-start {
    float: inline-start !important;
}

.inline-end {
    float: inline-end !important;
}

.image-preview .p-image-preview-container {
    width: 100%;
}

.breadcrumb-container {
    box-shadow: unset;
}

.p-orderlist-controls {
    display: none;
}

.pagination-layout {
    margin-top: 70px !important;
}

#cookie-popup { 
    direction: rtl;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
} 

#cookie-popup p { 
    margin-left: 0;
    display: inline-block;
} 

#cookie-popup button { 
    margin: 0;
    background-color: #324381;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: large;
    cursor: pointer;
    border-radius: 7px;
} 

#cookie-popup button:hover { 
    background-color: #1c2e6d; 
}
</style>
