<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import postscribe from "postscribe";

// Data
const items = ref([
  {
    label: "سياسة الخصوصية",
    // icon: 'pi pi-home',
    class: "custom-topbar-navbar-item",
    route: "/privacy-policy",
  },
  {
    label: "تواصل معنا",
    // icon: 'pi pi-home',
    class: "custom-topbar-navbar-item",
    route: "contact-us",
  },
]);

const op = ref();
const store = useStore();
// const facebook = createApp.config.globalProperties.Facebook;
const { layoutConfig, onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();
const loading = ref(false);

const searchDialog = ref(false);

onMounted(() => {
  bindOutsideClickListener();
  if (!isAdmin) {
    onMenuToggle();
  }
  postscribe(
    "#google-search-engine",
    `<script async src="https://cse.google.com/cse.js?cx=428f609745a9a482c"><\/script>`
  );
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
  if (!isAdmin) {
    onMenuToggle();
  }
});

// Computed
// const logoUrl = computed(() => {
//     return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
// });

const user = computed(() => {
  return store.getters.getProfile;
});

const isLogged = computed(() => {
  return store.getters.isAuthenticated;
});

const isAdmin = computed(() => {
  return store.getters.isAdmin;
});

// Methods
const logout = () => {
  loading.value = true;

  store
    .dispatch("authLogout")
    .then(() => {
      loading.value = false;
      window.location.href = "/";
    })
    .catch((err) => {
      loading.value = false;
      console.log(err);
    });
};

const toggleUser = (event) => {
  op.value.toggle(event);
};

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
  topbarMenuActive.value = false;
  router.push("/documentation");
};
const topbarMenuClasses = computed(() => {
  return {
    "layout-topbar-menu-mobile-active": topbarMenuActive.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const toggleSearch = () => {
  searchDialog.value = searchDialog.value ? false : true;
};
</script>

<template>
  <div class="top">
    <div class="layout-topbar">
      <router-link to="/" class="layout-topbar-logo" :aria-label="`${Home}`">
        <img :src="'https://toutaworld.com/assets/logo/Touta-World-White.png'" alt="logo" />
        <!-- <span>x7</span> -->
      </router-link>

      <button
        v-if="isAdmin"
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle()"
      >
        <i class="pi pi-bars"></i>
      </button>

      <button
        class="p-link layout-topbar-menu-button layout-topbar-button"
        title="authbtn"
        @click="onTopBarMenuButton()"
      >
        <i class="pi pi-ellipsis-v"></i>
      </button>

      <IconField
        iconPosition="left"
        class="search-container p-icon-field p-icon-field-left col-xxl-6 col-xl-5 col-lg-3 col-md-6 col-sm-7 col-xs-9"
      >
        <!-- <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon> -->
        <!-- <router-link to="/search">
                    <InputText placeholder="بحث" style="width: 100%;" />
                </router-link> -->
        <div id="google-search-engine"></div>
        <div class="gcse-search" enableAutoComplete="true"></div>
        <!-- <InputText placeholder="بحث" style="width: 100%;" @click.prevent="toggleSearch" /> -->
        <!-- <script async src="https://cse.google.com/cse.js?cx=05b998108954e40e0">
                </script>
                <div class="gcse-search"></div> -->
      </IconField>
      <div class="layout-topbar-menu" :class="topbarMenuClasses">
        <MegaMenu class="custom-top-navbar" :model="items">
          <template #item="{ item }">
            <router-link
              v-if="item.route"
              v-slot="{ href, navigate }"
              :to="item.route"
              :aria-label="`${item.name}`"
              custom
            >
              <a v-ripple :href="href" @click="navigate" :aria-label="`${item.label}`">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </router-link>
            <a v-else v-ripple :href="item.url" :target="item.target" :aria-label="`${item.label}`">
              <span :class="item.icon" />
              <span class="ml-2">{{ item.label }}</span>
            </a>
          </template>
        </MegaMenu>
        <button
          v-if="isLogged"
          @click="toggleUser"
          class="p-link layout-topbar-button layout-topbar-button-user"
          aria-haspopup="true"
          aria-controls="overlay_panel"
        >
          <img
            :src="'https://toutaworld.com/assets/profiles/' + user.profile_image"
            :alt="user.name + ' Profile Image'"
          />
        </button>
        <div v-else class="sign-btn">
          <router-link to="/auth/register" class="float-start" :aria-label="`register`">
            <Button
              class="topbar-auth-button"
              label="تسجيل"
              icon="pi pi-user-plus"
              severity="secondary"
              outlined
              text
              raised
            />
          </router-link>
          <!-- <Divider layout="vertical" class="float-start" /> -->
          <router-link to="/auth/login" class="float-start" :aria-label="`login`">
            <Button
              class="topbar-auth-button"
              label="دخول"
              icon="pi pi-sign-in"
              severity="secondary"
              outlined
              text
              raised
            />
          </router-link>
        </div>
        <OverlayPanel ref="op" appendTo="body">
          <div class="user-options-card">
            <ul class="user-options">
              <li>
                <p class="m-0">Hello {{ user.name }}</p>
              </li>
              <Divider />

              <li class="float-start text-center">
                <p class="m-0">
                  <button @click="onSettingsClick()" class="p-link layout-topbar-button">
                    <i class="pi pi-cog"></i>
                    <!-- <span>Settings</span> -->
                  </button>
                </p>
              </li>

              <Divider layout="vertical" class="float-start" />

              <li class="float-start text-center">
                <p class="m-0">
                  <button @click="logout()" class="p-link layout-topbar-button">
                    <i class="pi pi-sign-out"></i>
                    <!-- <span>Logout</span> -->
                  </button>
                </p>
              </li>
            </ul>
            <div class="fix-clear"></div>
          </div>
        </OverlayPanel>
      </div>
    </div>
    <Dialog
      v-model:visible="searchDialog"
      :style="{ width: '450px' }"
      :header="action"
      :modal="true"
      class="p-fluid"
    >
      <h1>Action</h1>
    </Dialog>
    <div class="card flex justify-content-center custom-loading" v-if="loading == true">
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
  </div>
</template>

<!-- .top > div {
    display: block;
} -->
<style lang="css">
.layout-topbar {
  height: 50px;
  background-color: #007fab !important;
}

.layout-topbar-logo {
  width: 180px !important;
}

.layout-topbar-logo img {
  height: 3.5rem;
}

.topbar-auth-button {
  background-color: #1e293b !important;
  color: #ffffff;
  font-size: 10px;
  border-radius: 5px;
}

.topbar-auth-button:hover {
  background-color: #151d29 !important;
}

.layout-topbar-button-user {
  max-height: 42px !important;
  border: 1px solid #dddddd;
  overflow: hidden;
}

.layout-topbar-button {
  background-color: #ffffff !important;
}

.layout-topbar-button img {
  width: 100%;
  height: 100%;
}

.layout-topbar-menu,
.p-megamenu-root-list {
  background-color: #007fab;
}

@media (max-width: 991px) {
  .gsc-search-button {
    position: absolute;
    left: 5px;
    top: 4px;
  }
  
  .layout-topbar .layout-topbar-menu.layout-topbar-menu-mobile-active {
    max-width: 160px;
    min-height: 67px;
    min-width: 160px;
  }

  .layout-topbar-button-user {
    max-height: 42px !important;
    border: 1px solid #dddddd !important;
  }

  .layout-topbar-button img {
    width: 100% !important;
    height: 100% !important;
  }

  .layout-topbar-logo img {
    height: 3.5rem;
  }
}

@media (max-width: 767px) {
  .layout-topbar-logo {
    width: 80px !important;
  }
}

@media (max-width: 767px) {
  .layout-topbar-logo {
    width: 80px !important;
  }
}

@media (max-width: 575px) {
  .layout-topbar-logo {
    width: 80px !important;
  }

  .layout-topbar-logo img {
    height: 1.5rem !important;
  }
}

.user-options-card {
  margin: 0;
  padding: 0;
  border: 1px solid #dddddd;
  border-radius: 7px;
}

.user-options {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user-options li {
  padding: 1rem;
  transition: all 300ms;
}

.user-options li:not(:first-child) {
  width: 50%;
}

.user-options li:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.p-divider {
  margin: 0 !important;
}

.sign-btn a {
  margin-top: 5px;
  margin-right: 7px;
  margin-left: 7px;
}

.custom-top-navbar {
  border: none;
  background-color: transparent;
}

.custom-top-navbar .p-megamenu-root-list {
  margin-bottom: 0;
}

.custom-topbar-navbar-item {
  width: 120px !important;
  margin: auto;
}

.custom-topbar-navbar-item .p-menuitem-content {
  text-align: center;
}

.custom-topbar-navbar-item .p-menuitem-content a {
  color: var(--surface-0)!important;
}

.layout-topbar .topbar {
  display: block !important;
  z-index: 99999999999999999 !important;
}

.layout-topbar .topbar .list ul {
  list-style-type: none;
}

.layout-topbar .topbar .list ul li {
  display: inline-block;
}

/* .gsc-control-wrapper-cse {
  direction: rtl !important;
} */

.gsc-control-cse {
  border: none !important;
}

.gsc-input-box {
  border: none !important;
}

.gsc-control-cse,
.gsc-input-box,
.gsib_a input,
.gsc-completion-container {
  background-color: #007fab !important;
  
}

.gsib_a,
.gsib_a input {
  background-color: #ffffff !important;
  border-radius: 5px;
  
}

.gsc-search-box {
  position: relative;
}

.gsc-input-box,
.gsc-search-button {
  border-radius: 7px !important;
  color: #ffffff;
}

.gsc-search-button {
  position: absolute;
  left: 5px;
  top: 1px;
  background-color: #1e293b !important;
  border: none !important;
  border-radius: 7px;
}

.gsib_b {
  position: absolute;
  left: 80px;
  top: 4px;
  z-index: 1;
}

.gsib_a input,
.gsc-completion-container {
  position: relative;
  color: #ffffff !important;
}

.gsc-input {
  direction: rtl;
  padding: 0 12px !important;
  background-position: right !important;
}

.gsc-search-button-v2 {
  padding: 9px 27px !important;
}

.gsc-control-cse {
  padding: 0 !important;
}

.gsc-result-info,
.gs-bidi-start-align,
.gs-visibleUrl,
.gs-visibleUrl-breadcrumb,
.gs-webResult,
.gs-result,
.gs-web-image-box,
.gs-promotion-image-box,
.gs-snippet {
  direction: rtl !important;
  text-align: right !important;
}

.gs-web-image-box,
.gs-promotion-image-box {
  float: right !important;
  padding: 2px 0px 2px 8px !important;
}

.gsc-orderby {
  direction: ltr !important;
  text-align: left !important;
}

.gcsc-branding {
  padding-right: 30px !important;
}

.gssb_a div {
  text-align: left !important;
}

.gssb_a img {
  max-width: 20px;
}

@media only screen and (max-width: 576px) {
  .search-container {
      width: 230px !important;
  }

  .gsc-search-button .gsc-search-button-v2 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
</style>
